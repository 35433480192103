/*

Sample Dealer 

1.	@id:"1"
2.	DealerAddress:"1720 Santa Monica Blvd"
3.	DealerAttributes:Object
4.	DealerCity:"Santa Monica"
5.	DealerDistance:"0.00"
6.	DealerEmail:"hondasm@eleadtrack.net"
7.	DealerFaxNo:"3104538890 "
8.	DealerHours:"Mon-Sat 9:00AM-9:00PM;Sun 10:00AM-7:00PM"
9.	DealerID:"206568"
10.	DealerInternetMngr:"Tommy Hsu"
11.	DealerLat:"34.027969"
12.	DealerLong:"-118.48087"
13.	DealerName:"Honda of Santa Monica"
14.	DealerPhoneNo:"3102644900 "
15.	DealerState:"CA"
16.	DealerURL:"http://www.hondaofsantamonica.com"
17.	DealerZip:"904041907"

*/


class hcpv_dealer {

    constructor(id, name, address, city, state, zip, lat, lon, phone, fax, email, hours, url, manager, distance, attributes) {

        this.id = id;
        this.name = name;
        this.address = address;
        this.city=city;
        this.state = state;
        this.zip = zip;
        this.lat = lat;
        this.lon = lon;
        this.phone = phone;
        this.fax = fax;
        this.email = email;
        this.hours = hours;
        if(hours!=null) {
            const schedules = this.hours.split(';').map(ele => ele.split(' '));
           
            this.hours = schedules;
        }
        this.url = url;
        this.manager = manager;
        this.distance = -1;
        if (distance != null) this.distance=distance.replace(" miles", "");
        this.attributes = attributes;
        this.count = 0;

    }

    // return 5 digit zip code
    zip5() {
        return this.zip.substr(0, 5);
    }

    phone2(){
        var phone2=this.phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        //console.log(phone2);
        return phone2;
    }

}


